import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DeliveryLeadTime } from '@1po/1po-bff-fe-spec/generated/backoffice/delivery_lead_time/response/DeliveryLeadTimeResponse';
import {
  ReferenceStock,
  Warehouse,
  WarehouseStockDetail,
} from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceStock';
import { DealerType } from '@1po/1po-bff-fe-spec/generated/user/model/CommercialLink';
import { TFunction } from 'i18next';
import moment from 'moment';
import { RootState } from 'app/AppStore';
import { Ban, TruckIcon } from 'assets/icons';
import { ReactComponent as GlobeIcon } from 'assets/icons/globe.svg';
import { ReactComponent as HourglassHalfIcon } from 'assets/icons/hourglass-half.svg';
import { ReactComponent as IndustryIcon } from 'assets/icons/industry.svg';
import { OneBox, ShortStockDescription } from 'components/StockInfo/StockDisplay.styled';
import { getDeliveryLeadTime } from 'domains/deliveryLeadTime/DeliveryLeadTime.store';
import { getDealerType, getUserContext } from 'domains/user';
import { ThemeDisplayStyleKeyType } from 'styles';
import { displayStyle } from 'styles/displayStyle';
import { Box, Flex, Icon, MarginBox, Text, WithTooltip } from 'UI';
import { getData, NO_DATA, textFormatter } from 'utils';

const STOCK_DELIVERY_DATA_CY = 'stock-delivery-text';

interface StockTextsI {
  warehouse: string;
  description: string[];
}

const _MS_PER_DAY = 1000 * 60 * 60 * 24;

const getDaysUntilDelivered = (expectedDeliveryDate: string | undefined, relativeDate: Date): number | null => {
  if (!expectedDeliveryDate) {
    return null;
  }
  const deliveryDate = new Date(expectedDeliveryDate);
  if (!moment(new Date(deliveryDate)).isValid()) {
    return null;
  }
  const utcDelivery = Date.UTC(deliveryDate.getFullYear(), deliveryDate.getMonth(), deliveryDate.getDate());
  const utcToCompare = Date.UTC(relativeDate.getFullYear(), relativeDate.getMonth(), relativeDate.getDate());

  return Math.floor((utcDelivery - utcToCompare) / _MS_PER_DAY);
};

export const getDisplayStyle = (
  type: Warehouse,
  expectedDeliveryDate: string | undefined,
  depan: boolean | undefined,
  relativeDate: Date,
  isMKTP: boolean,
): ThemeDisplayStyleKeyType => {
  if (isMKTP) {
    return 'mktp';
  }
  switch (type) {
    case 'CENTRAL':
    case 'REGION':
    case 'COUNTRY':
      return 'info';
    case 'LOCAL':
    case 'MANUFACTURER': {
      const daysUntilDelivered = getDaysUntilDelivered(expectedDeliveryDate, relativeDate);
      if (daysUntilDelivered === null) {
        return 'success';
      }
      return daysUntilDelivered <= 1 ? 'info' : 'warning';
    }
    case 'WW':
      return 'viewed_link';
    case 'EXADIS': {
      if (depan) {
        return 'viewed_link';
      }
      const daysUntilDelivered = getDaysUntilDelivered(expectedDeliveryDate, relativeDate);
      return daysUntilDelivered !== null && daysUntilDelivered > 2 ? 'viewed_link' : 'success';
    }
    case 'MARKETPLACE':
      return 'disabled';
    case 'OTHER':
    case 'DELAYED':
    default:
      return 'warning';
  }
};

export const getIcon = (
  type: Warehouse,
  expectedDeliveryDate: string | undefined,
  depan: boolean | undefined,
  relativeDate: Date,
  isMKTP: boolean,
  size?: number | undefined,
) => {
  const getIconComponent = (type: Warehouse, expectedDeliveryDate: string | undefined) => {
    if (isMKTP) {
      return TruckIcon;
    }
    switch (type) {
      case 'LOCAL':
        return TruckIcon;
      case 'CENTRAL':
      case 'REGION':
      case 'COUNTRY':
      case 'MANUFACTURER':
        return IndustryIcon;
      case 'WW':
        return GlobeIcon;
      case 'DELAYED':
        return HourglassHalfIcon;
      case 'EXADIS': {
        const daysUntilDelivered = getDaysUntilDelivered(expectedDeliveryDate, relativeDate);

        if (daysUntilDelivered === null && !depan) {
          return Ban;
        }
        return TruckIcon;
      }
      case 'MARKETPLACE':
      default:
        return TruckIcon;
    }
  };

  return (
    <Icon
      IconComponent={getIconComponent(type, expectedDeliveryDate)}
      size={size ?? 20}
      mr={5}
      color={displayStyle[getDisplayStyle(type, expectedDeliveryDate, depan, relativeDate, isMKTP)].color}
      display={'block'}
      dataCy={'stock-icon'}
      noPointer
    />
  );
};

export function getStockTexts(
  t: TFunction,
  stock: WarehouseStockDetail,
  basketCount: number,
  relativeDate: Date,
  depan: boolean | undefined,
): StockTextsI {
  const { type, availableQuantity, confirmedQuantity, expectedDeliveryDate, deliveryInformation } = stock;

  const deliveryInformationArr = deliveryInformation?.split('|').map((inf) => inf.trim());
  const deliveryInformationOneLine = deliveryInformationArr?.[0];
  const deliveryInformationTheRest = deliveryInformationArr?.slice(1);

  switch (type) {
    case 'LOCAL':
      return {
        warehouse: t('catalog.reference.stock.r1', 'R1:'),
        description:
          basketCount === 0
            ? [
                t('catalog.reference.stock.r1.units', '{{count}} units in stock at the R1 warehouse', {
                  count: availableQuantity,
                }),
              ]
            : [
                t(
                  'catalog.reference.stock.r1.description_with_basket_count',
                  '{{basketCount}}/{{count}} unit(s) in stock at the R1 warehouse',
                  {
                    basketCount: confirmedQuantity,
                    count: availableQuantity,
                  },
                ),
              ],
      };
    case 'REGION':
      return {
        warehouse: t('catalog.reference.stock.regional', 'Regional warehouse:'),
        description:
          basketCount === 0
            ? [t('catalog.reference.stock.regional.description', 'In stock at the Regional warehouse')]
            : [t('catalog.reference.stock.units', '{{count}} units', { count: confirmedQuantity })],
      };
    case 'CENTRAL':
      return {
        warehouse: t('catalog.reference.stock.central', 'Central warehouse:'),
        description:
          basketCount === 0
            ? [t('catalog.reference.stock.central.description', 'In stock at the Central warehouse')]
            : [t('catalog.reference.stock.units', '{{count}} units', { count: confirmedQuantity })],
      };
    case 'COUNTRY':
      return {
        warehouse: t('catalog.reference.stock.country', 'Country warehouse:'),
        description:
          basketCount === 0
            ? [t('catalog.reference.stock.country.description', 'In stock at the Country warehouse')]
            : [t('catalog.reference.stock.units', '{{count}} units', { count: confirmedQuantity })],
      };
    case 'MANUFACTURER': {
      const manufacturerDeliveryText = expectedDeliveryDate
        ? textFormatter.formatDateShort(new Date(expectedDeliveryDate))
        : undefined;
      return {
        warehouse: t('catalog.reference.stock.manufacturer', 'Manufacturer:'),
        description:
          basketCount === 0
            ? [
                t('catalog.reference.stock.manufacturer.in_stock', 'In stock, will be delivered by {{date}}', {
                  date: manufacturerDeliveryText,
                }),
              ]
            : [
                t('catalog.reference.stock.manufacturer.units', '{{count}} units will be delivered by {{date}}', {
                  count: confirmedQuantity,
                  date: manufacturerDeliveryText,
                }),
              ],
      };
    }
    case 'OTHER':
      return {
        warehouse: t('catalog.reference.stock.other_location', 'Other:'),
        description: [
          t(
            'catalog.reference.stock.other_location.more_information',
            'Please, contact your dealer for more information',
          ),
        ],
      };
    case 'WW':
      return {
        warehouse: t('catalog.reference.stock.worldwide', 'Worldwide warehouse:'),
        description:
          basketCount === 0
            ? [t('catalog.reference.stock.worldwide.description', 'In stock at the Worldwide warehouse')]
            : [t('catalog.reference.stock.units', '{{count}} units', { count: confirmedQuantity })],
      };
    case 'DELAYED':
      return {
        warehouse: t('catalog.reference.stock.restocking', 'Restocking:'),
        description: [
          t('catalog.reference.stock.units', '{{count}} units', {
            count: confirmedQuantity,
          }),
        ],
      };
    case 'INVALID':
      return {
        warehouse: t('catalog.reference.stock.stock_noinfo', 'Stock information unavailable.'),
        description: [''],
      };
    case 'EXADIS': {
      const daysUntilDelivered = getDaysUntilDelivered(expectedDeliveryDate, relativeDate);
      if ((daysUntilDelivered === null && !depan) || basketCount === 0) {
        return {
          warehouse: '',
          description: [''],
        };
      }
      return {
        warehouse: t('catalog.reference.stock.IAM_delivery_pieces', '{{count}} pieces:', {
          count: confirmedQuantity,
        }),
        description: [''],
      };
    }
    case 'MARKETPLACE': {
      if (basketCount === 0) {
        return {
          warehouse: '',
          description: [
            t(
              'catalog.reference.stock.delivery_information_without_quantity',
              'In stock, will be delivered by {{deliveryInformation}}',
              {
                deliveryInformation: deliveryInformationOneLine,
              },
            ),
            ...(deliveryInformationTheRest ?? []),
          ],
        };
      }
      return {
        warehouse: t('catalog.reference.stock.IAM_delivery_pieces', '{{count}} pieces:', {
          count: confirmedQuantity,
        }),
        description: [
          t(
            'catalog.reference.stock.delivery_information_with_quantity',
            'will be delivered by {{deliveryInformation}}',
            {
              deliveryInformation: deliveryInformationOneLine,
            },
          ),
          ...(deliveryInformationTheRest?.map((r) => {
            return t(
              'catalog.reference.stock.delivery_information_with_quantity',
              'will be delivered by {{deliveryInformation}}',
              {
                deliveryInformation: r,
              },
            );
          }) ?? []),
        ],
      };
    }
    default:
      return {
        warehouse: '',
        description: [''],
      };
  }
}

export const getDeliveryText = (
  t: TFunction,
  type: Warehouse,
  leadTime: DeliveryLeadTime | undefined,
  expectedDeliveryDate: string | undefined,
  depan: boolean | undefined,
  dealerType: DealerType | undefined,
  relativeDate: Date,
) => {
  switch (type) {
    case 'REGION':
    case 'COUNTRY':
    case 'CENTRAL': {
      if (!leadTime || leadTime.isDisabled || !leadTime.from || !leadTime.to) {
        return '';
      }
      const time =
        leadTime.time === 'HOURS'
          ? t('catalog.reference.stock.usually_delivery_part2_hours', ' hours')
          : t('catalog.reference.stock.usually_delivery_part2_day', ' days');
      return (
        t('catalog.reference.stock.usually_delivery_part1', ', usually delivered within {{from}} to {{to}}', {
          from: leadTime.from,
          to: leadTime.to,
        }) + time
      );
    }
    case 'EXADIS': {
      if (depan) {
        return handleExadisDepan(dealerType, t);
      }

      const daysUntilDelivered = getDaysUntilDelivered(expectedDeliveryDate, relativeDate);

      if (daysUntilDelivered === null) {
        return t('catalog.reference.stock.IAM_delivery_unknown', 'Delivery time unknown');
      }

      const deliveryDate = new Date(expectedDeliveryDate as string);
      const cutoffHours = textFormatter.formatDateHourMinute(deliveryDate);

      return handleExadisNonDepan(deliveryDate, dealerType, t, cutoffHours);
    }
    default:
      return '';
  }
};

function handleExadisDepan(dealerType: string | undefined, t: TFunction) {
  return getDeliveryTextByDealer(
    dealerType,
    t('catalog.reference.stock.IAM_special_order_delivery', 'Special order (>72h)'),
    t('catalog.reference.stock.IAM_special_order_delivery_to_R1', 'Special order (>72h at your R1)'),
  );
}

function handleExadisNonDepan(deliveryDate: Date, dealerType: string | undefined, t: TFunction, cutoffHours: string) {
  const dateFormatted = textFormatter.formatDateLongNoYear(deliveryDate);
  return getDeliveryTextByDealer(
    dealerType,
    t('catalog.reference.stock.IAM_delivery', 'will be delivered on {{date}} before {{cutoffHours}}', {
      date: dateFormatted,
      cutoffHours,
    }),
    t('catalog.reference.stock.IAM_delivery_to_R1', 'will be delivered to your R1 on {{date}} before {{cutoffHours}}', {
      date: dateFormatted,
      cutoffHours,
    }),
  );
}

const getDeliveryTextByDealer = (dealerType: string | undefined, r1Text: string, nonR1Text: string) => {
  if (dealerType === 'R1') {
    return r1Text;
  }
  return nonR1Text;
};

export interface DescriptionI {
  type: Warehouse;
  description: string[];
  deliveryText: string;
  displayStyle?: ThemeDisplayStyleKeyType | ThemeDisplayStyleKeyType[] | false;
}

function BaseDescription({ description, deliveryText, displayStyle }: DescriptionI) {
  return (
    <>
      {description.length > 1 ? (
        <Flex direction={'column'}>
          {description.map((description, i) => (
            <Text key={i} type={'light_14_black_65'} displayStyle={displayStyle} dataCy={STOCK_DELIVERY_DATA_CY}>
              {description}
            </Text>
          ))}
          <Text type={'light_14_black_65'} displayStyle={displayStyle} dataCy={STOCK_DELIVERY_DATA_CY}>
            {deliveryText}
          </Text>
        </Flex>
      ) : (
        <Text type={'light_14_black_65'} displayStyle={displayStyle} dataCy={STOCK_DELIVERY_DATA_CY}>
          {description}
          {deliveryText}
        </Text>
      )}
    </>
  );
}

function NarrowDescription({ description, deliveryText, displayStyle }: DescriptionI) {
  return (
    <ShortStockDescription maxWidth={145}>
      <WithTooltip title={`${description}${deliveryText}`}>
        {description.length > 1 ? (
          <OneBox>
            {description.map((description, i) => (
              <Text
                ellipsis
                key={i}
                type={'light_14_black_65'}
                displayStyle={displayStyle}
                dataCy={STOCK_DELIVERY_DATA_CY}
              >
                {description}
              </Text>
            ))}
            <Text ellipsis type={'light_14_black_65'} displayStyle={displayStyle} dataCy={STOCK_DELIVERY_DATA_CY}>
              {deliveryText}
            </Text>
          </OneBox>
        ) : (
          <Text ellipsis type={'light_14_black_65'} displayStyle={displayStyle} dataCy={STOCK_DELIVERY_DATA_CY}>
            {description}
            {deliveryText}
          </Text>
        )}
      </WithTooltip>
    </ShortStockDescription>
  );
}

export interface StockTextProps {
  stock: ReferenceStock | NO_DATA;
  quantity?: number | undefined;
  type: Warehouse;
  narrow?: boolean;
  displayIcon?: boolean;
  index: number;
  isMKTP: boolean;
}

export function StockText({ stock, quantity, type, narrow, displayIcon, index, isMKTP }: Readonly<StockTextProps>) {
  const deliveryLeadTime = useSelector((state: RootState) => getDeliveryLeadTime(state, type));
  return (
    <StockTextWithDeliveryType
      stock={stock}
      quantity={quantity}
      type={type}
      narrow={narrow}
      deliveryLeadTime={deliveryLeadTime}
      displayIcon={displayIcon}
      index={index}
      isMKTP={isMKTP}
    />
  );
}

export interface StockTextWithDeliveryTypeProps {
  stock: ReferenceStock | NO_DATA;
  quantity?: number;
  type: Warehouse;
  narrow?: boolean;
  deliveryLeadTime: DeliveryLeadTime | undefined;
  displayIcon?: boolean;
  // as we can have multiple stocks of the same type, we have to use index in order to know which one should be present on current row
  index: number;
  dealerType?: DealerType | undefined;
  r1Country?: string;
  // date to which we count the stock delivery time
  relativeDate?: Date;
  isMKTP: boolean;
}

export function StockTextWithDeliveryType({
  stock,
  quantity,
  type,
  narrow,
  deliveryLeadTime,
  displayIcon,
  index,
  relativeDate = new Date(),
  isMKTP,
}: Readonly<StockTextWithDeliveryTypeProps>) {
  const { t } = useTranslation();
  const stockData = getData(stock);
  const dealerType = useSelector(getDealerType);
  const { r1Country } = useSelector(getUserContext);

  if (!stockData) {
    return null;
  }
  const currentStock = stockData.warehouses[index];
  if (currentStock.confirmedQuantity === 0) {
    return null;
  }
  const texts = getStockTexts(t, currentStock, quantity ?? 0, relativeDate, currentStock.depan);
  const deliveryText = getDeliveryText(
    t,
    type,
    deliveryLeadTime,
    currentStock.expectedDeliveryDate,
    currentStock.depan,
    dealerType,
    relativeDate,
  );

  const showStockForExadis = currentStock.type !== 'EXADIS' || r1Country === 'FR';

  const Description: FunctionComponent<DescriptionI> = narrow ? NarrowDescription : BaseDescription;

  return (
    <>
      {showStockForExadis && (
        <Flex align={'flex-start'} dataCy={'stocks-delivery'}>
          {displayIcon && getIcon(type, currentStock.expectedDeliveryDate, currentStock.depan, relativeDate, isMKTP)}
          <Box maxWidth={20}>
            <Text
              type={'light_14_bold_black_65'}
              displayStyle={getDisplayStyle(
                type,
                currentStock.expectedDeliveryDate,
                currentStock.depan,
                relativeDate,
                isMKTP,
              )}
              noWrap
              ellipsis
              dataCy={'stock-warehouse'}
            >
              {stockData.establishmentName ? `${stockData.establishmentName} ${texts.warehouse}` : texts.warehouse}
            </Text>
          </Box>
          <MarginBox ml={5} />
          <Description type={type} description={texts.description} deliveryText={deliveryText} />
        </Flex>
      )}
    </>
  );
}
