import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Reference, Tire } from '@1po/1po-bff-fe-spec/generated/estimate/response/GetEstimate';
import { RootState } from 'app/AppStore';
import { CheckCircleIcon } from 'assets/icons';
import { DataContainer } from 'components/DataContainer';
import {
  getEstimateB2BPrices,
  getEstimateB2BStocks,
  getEstimateById,
  getEstimateOrderStatus,
  getReferencesWithValidPrices,
  getTiresWithValidPrices,
  setEstimateOrderIsUrgent,
  setEstimateOrderMark,
} from 'domains/estimate/Estimate.store';
import { EstimateOrderViewReference } from 'domains/estimate/Estimate.types';
import { CartItemWrapper, SInput, UrgentWrapper } from 'pages/CartPage/CartStep/CartItems/CartItems.styled';
import ConfirmationStep from 'pages/CartPage/ConfirmationStep';
import B2BEstimateOrderReferenceTable from 'pages/EstimatePage/OrderEstimate/B2BEstimateOrderReferenceTable';
import B2BEstimateOrderValidationStep from 'pages/EstimatePage/OrderEstimate/B2BEstimateOrderValidationStep';
import { theme } from 'styles';
import { Checkbox, Flex, Icon, MarginBox, Modal, Text } from 'UI';
import { FOUND, getData, textFormatter, useBreakpointSelectorFull, useExtraLarge } from 'utils';

const B2BEstimateOrderDialog = ({
  isOpen,
  setOrderEstimateOpen,
  estimateId,
}: {
  isOpen: boolean;
  setOrderEstimateOpen: (b: boolean) => void;
  estimateId: string;
}) => {
  const { t } = useTranslation();
  const estimate = getData(useSelector((state: RootState) => getEstimateById(state, estimateId)));
  const dispatch = useDispatch();

  const canPlaceUrgentOrders = estimate?.clientContact?.canPlaceUrgentOrders;
  const estimateOrderDetails = estimate?.estimateOrderItemDetails;
  const orderMark = estimateOrderDetails?.orderMark;

  const referencesWithPrices = useSelector((state: RootState) => getReferencesWithValidPrices(state, estimateId));
  const tiresWithPrices = useSelector((state: RootState) => getTiresWithValidPrices(state, estimateId));
  const orderStatus = useSelector((state: RootState) => getEstimateOrderStatus(state, estimateId));

  const breakpoint = useExtraLarge();
  const modalWidth = useBreakpointSelectorFull()({ xs: 1150, sm: 1150, md: 1150, lg: 1150, xl: 1600, xxl: 1820 });

  const refs = referencesWithPrices
    .map((ref) => ref.referenceNumber)
    .concat(tiresWithPrices.map((ref) => ref.referenceNumber));

  const isUrgentOrder = refs.every((ref) => estimateOrderDetails?.isUrgentDelivery.get(ref));

  const prices = useSelector((state: RootState) => getEstimateB2BPrices(state, estimateId));
  const stocks = useSelector((state: RootState) => getEstimateB2BStocks(state, estimateId));

  const selectUrgent = () => {
    refs.forEach((ref) => {
      dispatch(setEstimateOrderIsUrgent({ estimateId, referenceNumber: ref, isUrgentDelivery: !isUrgentOrder }));
    });
  };

  const hasPriceOrStock = (item: Reference | Tire) => {
    const stock = stocks.get(item.referenceNumber);
    const price = prices.get(item.referenceNumber);
    return stock?.data !== undefined && price?.data !== undefined;
  };

  const getReferencesViewData = (): EstimateOrderViewReference[] => {
    const realReferences = referencesWithPrices.filter((ref) => {
      const price = prices.get(ref.referenceNumber);
      return price?.searchStatus === FOUND;
    });
    const realTires = tiresWithPrices.filter((ref) => {
      const price = prices.get(ref.referenceNumber);
      return price?.searchStatus === FOUND;
    });

    const mapToViewData = (references: Reference[] | Tire[]) => {
      return references.map((ref) => {
        return {
          referenceNumber: ref.referenceNumber,
          designation: ref.designation,
          quantity: ref.quantity,
          garagePrice: ref.garagePrice,
          priceVatExcluded: ref.priceVatExcluded,
          isMktp: 'referenceSource' in ref && ref.referenceSource === 'MARKETPLACE',
          hasPriceAndStock: hasPriceOrStock(ref),
          estimateId,
        } as EstimateOrderViewReference;
      });
    };

    const catalogReferences = mapToViewData(realReferences ?? []);
    const tireReferences = mapToViewData(realTires ?? []);
    return catalogReferences.concat(tireReferences);
  };

  const referencesToOrder = referencesWithPrices.concat(tiresWithPrices).filter((ref) => hasPriceOrStock(ref));

  return (
    <Modal
      open={isOpen}
      onCancel={() => setOrderEstimateOpen(false)}
      footer={''}
      closable
      width={modalWidth}
      maskClosable={!orderStatus}
    >
      <Text type={'h1'}>
        {t('estimate.order_summary_for_client', 'Order summary for {{clientName}}', {
          clientName: estimate?.clientContact.name ?? '',
        })}
      </Text>
      <MarginBox mt={50} />
      {!orderStatus ? (
        <Flex
          gap={30}
          justify={'space-around'}
          direction={breakpoint ? 'row' : 'column'}
          align={breakpoint ? 'initial' : 'center'}
        >
          <CartItemWrapper expanded={true} setWidth={1060}>
            <Flex direction={'row'} minWidth={1060} maxWidth={1060}>
              {canPlaceUrgentOrders ? (
                <Flex justify={'flex-end'}>
                  <MarginBox mr={67} mt={26}>
                    <UrgentWrapper onClick={selectUrgent}>
                      <Flex>
                        <Checkbox checked={isUrgentOrder} />
                        <Icon
                          mr={5}
                          ml={5}
                          IconComponent={CheckCircleIcon}
                          size={12}
                          display={'inline'}
                          color={theme.color.brand}
                          background={theme.color.brand_black}
                          round
                        />
                        <Text type={'link'}>{t('cart.urgent_delivery', 'Urgent delivery')}</Text>
                      </Flex>
                    </UrgentWrapper>
                    <MarginBox mt={1} ml={20}>
                      <Text type={'light_dimmer'}>
                        {t('cart.urgent_delivery_note', '(Select for faster delivery)')}
                      </Text>
                    </MarginBox>
                  </MarginBox>
                </Flex>
              ) : (
                <Flex />
              )}
              <MarginBox mt={13} mr={36}>
                <Flex direction={'row'}>
                  <Text type={'highlight'}>{t('common.price.total_VAT_excl', 'Total VAT. Excl')}</Text>
                </Flex>
                <Flex direction={'row'}>
                  <Text type={'highlight'}>
                    {textFormatter.formatCurrency(estimate?.totalPrice?.priceVatExcluded ?? '', 'EUR')}
                  </Text>
                </Flex>
              </MarginBox>
            </Flex>
            <Flex direction={'row'}>
              <MarginBox ml={37} mt={17}>
                <SInput
                  maxLength={40}
                  onChange={(newOrderMark) => {
                    dispatch(setEstimateOrderMark({ estimateId, orderMark: newOrderMark }));
                  }}
                  bordered
                  initialValue={orderMark}
                  placeholder={t('order.order_mark', 'Click here to add your order mark')}
                />
              </MarginBox>
            </Flex>
            <B2BEstimateOrderReferenceTable estimateId={estimateId} references={getReferencesViewData()} />
          </CartItemWrapper>
          <Flex size={0}>
            <B2BEstimateOrderValidationStep estimateId={estimateId} references={referencesToOrder} />
          </Flex>
        </Flex>
      ) : (
        <Flex minHeight={400} align={'center'}>
          <DataContainer data={orderStatus}>
            <ConfirmationStep noReset noDetail />
          </DataContainer>
        </Flex>
      )}
    </Modal>
  );
};

export default B2BEstimateOrderDialog;
