import React, { useCallback, useRef, useState } from 'react';
import { Select } from 'antd';
import {
  CreatePromotionModel,
  FamilyCodesItem,
  PromotionItem,
  PromotionTypeLocal,
} from 'domains/promotion/Promotion.types';
import {
  DefaultTag,
  DisabledTag,
  ErrorTag,
} from 'pages/BackOfficePage/BackOfficeCategories/Promotion/CreatePromotion/CreatePromotion.styled';
import { FamilyCodesTable } from 'pages/BackOfficePage/BackOfficeCategories/Promotion/CreatePromotion/PriceOptionStep/FamilyCodesTable';
import { MarginBox, Text, WithTooltip } from 'UI';
import { compareArrays } from 'utils';

type CustomTagProps = {
  label: React.ReactNode;
  value: any;
  onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

const TagComponent = ({
  promotionType,
  label,
  item,
  isRemovable,
  onClose,
}: {
  promotionType: PromotionTypeLocal;
  label: React.ReactNode;
  item: PromotionItem | undefined;
  isRemovable: boolean;
  onClose: () => void;
}) => {
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return !isRemovable ? (
    <DisabledTag onMouseDown={onPreventMouseDown} closable={isRemovable} onClose={onClose}>
      <Text type={'text'}>{label}</Text>
    </DisabledTag>
  ) : item && promotionType !== 'BANNER' && (item.alreadyPromoted || item.alreadyLocallyPromoted) ? (
    <ErrorTag onMouseDown={onPreventMouseDown} closable={isRemovable} onClose={onClose}>
      <Text type={'text'}>{label}</Text>
    </ErrorTag>
  ) : (
    <DefaultTag onMouseDown={onPreventMouseDown} closable={isRemovable} onClose={onClose}>
      <Text type={'text'}>{label}</Text>
    </DefaultTag>
  );
};

interface FamilyCodesInputProps {
  index: number;
  inputValue: string[];
  data: FamilyCodesItem[];
  onUpdate: (items: string[]) => void;
  promotion: CreatePromotionModel;
}

export const FamilyCodesInput = ({ index, inputValue, data, onUpdate, promotion }: FamilyCodesInputProps) => {
  const [showTable, setShowTable] = useState<boolean>(false);
  const [searchResult, setSearchResult] = useState<FamilyCodesItem[]>([]);
  const tableRef = useRef<HTMLDivElement>(null);
  const rowRefs = useRef<(HTMLDivElement | null)[]>([]);

  const [highlightedRowIndex, setHighlightedRowIndex] = useState<number>(0);

  const handleMove = useCallback((offset: number) => {
    setHighlightedRowIndex((prevIndex) => prevIndex + offset);
  }, []);

  const handleDown = useCallback(() => handleMove(1), [handleMove]);
  const handleUp = useCallback(() => handleMove(-1), [handleMove]);

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'ArrowUp') {
      if (highlightedRowIndex === 0) {
        setHighlightedRowIndex(searchResult.length - 1);
      } else {
        handleUp();
      }
    }
    if (event.key === 'ArrowDown') {
      if (highlightedRowIndex === searchResult.length - 1) {
        setHighlightedRowIndex(0);
      } else {
        handleDown();
      }
    }
    if (event.key === 'Enter' && searchResult[highlightedRowIndex]) {
      handleChange(searchResult[highlightedRowIndex].familyCode);
      tableRef.current?.focus();
    }
  };

  const handleChange = (code: string) => {
    const upperCaseItems = [...inputValue, code].map((x) => x.toUpperCase());
    const filteredItems = upperCaseItems.filter((item, idx) => upperCaseItems.indexOf(item) === idx);
    if (compareArrays(upperCaseItems, filteredItems)) {
      onUpdate(filteredItems);
    }
    setShowTable(false);
  };

  return (
    <React.Fragment>
      <MarginBox mt={20} />
      <Select
        mode={'tags'}
        open={showTable}
        value={inputValue}
        dropdownRender={() => {
          return (
            <div ref={tableRef} tabIndex={0} style={{ width: '100%' }}>
              <FamilyCodesTable
                familyCodesData={searchResult}
                onSelect={handleChange}
                rowRefs={rowRefs}
                highlightedRowIndex={highlightedRowIndex}
              />
            </div>
          );
        }}
        onSearch={(value) => {
          const filtered =
            data?.filter((item: FamilyCodesItem) => {
              const isFamilyCodeMatch = item?.familyCode?.toLowerCase().includes(value ?? '');
              const isPromoCodeMatch = item?.promoCode?.toString().includes(value ?? '');
              return isFamilyCodeMatch || isPromoCodeMatch;
            }) || [];
          if (value.length >= 3 && filtered?.length > 0) {
            setSearchResult(filtered);
            setShowTable(true);
          } else {
            setShowTable(false);
          }
        }}
        onDropdownVisibleChange={(visible) => {
          if (!visible) {
            setShowTable(false);
          }
        }}
        tagRender={(props: CustomTagProps) => {
          // eslint-disable-next-line react/prop-types
          const item = promotion.commercialFamilyCodes
            ? promotion.commercialFamilyCodes[index].items.find((code) => code.item === props.label)
            : undefined;
          const tooltipItem = data.find((i) => i.familyCode === props.value);
          return (
            <WithTooltip
              title={
                <div>
                  {Object.entries(tooltipItem ?? {}).map(([key, value]) => (
                    <div key={key}>
                      {key
                        .replace(/([A-Z])/g, ' $1')
                        .toLowerCase() // Convert entire string to lowercase
                        .replace(/^./, (str) => str.toUpperCase())}
                      : {value}
                    </div>
                  ))}
                </div>
              }
            >
              {/*keep the empty tag*/}
              <>
                <TagComponent
                  promotionType={promotion.promotionType}
                  label={props.label}
                  item={item}
                  isRemovable={true}
                  onClose={() => {
                    onUpdate(inputValue.filter((i) => i !== props.value));
                    props.onClose();
                  }}
                />
              </>
            </WithTooltip>
          );
        }}
        onKeyDown={handleKeyPress}
        showArrow
        showSearch
        tokenSeparators={[' ', ',']}
        placeholder={''}
        bordered
      />
    </React.Fragment>
  );
};
