/* eslint-disable max-len */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReferencePlateLine } from '@1po/1po-bff-fe-spec/generated/catalog/references/dh/model/Reference';
import { ReferenceStockTradingDataRequestDetail } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/request/GetReferencesStockRequest';
import { ReferenceTradingDataRequestDetail } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/request/GetReferenceTradingDataRequest';
import { RootState } from 'app/AppStore';
import { getBasketOtherSection } from 'domains/basket/Basket.store';
import { TIRE } from 'domains/catalog/Catalog.types';
import {
  fetchCrossSellingReferencesRequestSaga,
  fetchEquivalentReferencesRequestSaga,
  fetchPlatesForReferenceRequestSaga,
  fetchReferencesDiscountsRequestSaga,
  fetchReferencesPriceRequestSaga,
  fetchReferencesRepairPriceRequestSaga,
  fetchReferencesStocksRequestSaga,
  fetchReuseStocksRequestSaga,
  getDHReference,
  getDHReferences,
  getDHReferencesMap,
  getDiscountStatuses,
  getEquivalentReferences,
  getIAMReferencesMap,
  getPricesMap,
  getReferenceSources,
  getReferenceTypes,
  getRepairPricesMap,
  getReuseStocksMap,
  getStocksMap,
} from 'domains/references/References.store';
import { REUSE_NODE_IDS } from 'domains/references/References.types';
import { getTireSetParam } from 'domains/tires/Tire.store';
import { getData, hasData, SearchData } from 'utils';
import { Country } from 'utils/i18n/Country';
import { getTradingProfile, getUserCountry } from '../user';
/* eslint-enable max-len */

export const useFetchCrossSellingReferences = (references: string[], vehicleKey: string | undefined): void => {
  const dispatch = useDispatch();
  const allLoadedReferences = useSelector((state: RootState) =>
    getDHReferences(state, { referenceNumbers: references, vehicleKey }),
  );
  const relevantLoadedReferences = allLoadedReferences.filter((r) => references.includes(r.referenceNumber));
  const tradingProfile = useSelector(getTradingProfile);
  const isTradingProfileComplete = tradingProfile?.isComplete();
  const requestReferences = references.filter((ref) => {
    const loadedCrossSells = relevantLoadedReferences.find((loadedRef) => loadedRef.referenceNumber === ref)
      ?.crossSelling;
    return !hasData(loadedCrossSells) || loadedCrossSells.length === 0;
  });

  const referencesJson = JSON.stringify(references);
  useEffect(() => {
    if (
      isTradingProfileComplete &&
      requestReferences.length > 0 &&
      relevantLoadedReferences.length === references.length
    ) {
      dispatch(fetchCrossSellingReferencesRequestSaga({ references: requestReferences, vehicleKey }));
    }
    // eslint-disable-next-line
  }, [dispatch, referencesJson, isTradingProfileComplete, relevantLoadedReferences.length, vehicleKey]);
};

export const useFetchPrices = (referenceNumbers: string[], vehicleKey?: string): void => {
  const dispatch = useDispatch();
  const tradingProfile = useSelector(getTradingProfile);
  const isTradingProfileComplete = tradingProfile?.isComplete();
  const prices = useSelector((state: RootState) => getPricesMap(state, referenceNumbers));
  const references = useSelector((state: RootState) => getDHReferencesMap(state, { referenceNumbers, vehicleKey }));
  const iamReferences = useSelector((state: RootState) => getIAMReferencesMap(state, { referenceNumbers, vehicleKey }));

  useEffect(() => {
    const missingReferenceNumbers = Array.from(prices.entries())
      .filter(([_, value]) => value === undefined)
      .map(([key, _]) => key);

    const referencesToFetch = missingReferenceNumbers.map((r) => {
      return {
        referenceNumber: r,
        commercialFamily: getData(references.get(r))?.familyCode ?? '',
        supplierCode: getData(iamReferences.get(r))?.supplierCode,
        origin: getData(iamReferences.get(r))?.origin,
        referenceSource: getData(iamReferences.get(r))?.referenceSource,
      };
    });

    if (isTradingProfileComplete && referencesToFetch.length > 0) {
      dispatch(
        fetchReferencesPriceRequestSaga({
          references: referencesToFetch,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, referenceNumbers]);
};

export const useFetchDiscounts = (references: ReferenceTradingDataRequestDetail[]): void => {
  const dispatch = useDispatch();
  const tradingProfile = useSelector(getTradingProfile);
  const isTradingProfileComplete = tradingProfile?.isComplete();
  const referenceNumbers = references.map((r) => r.referenceNumber);
  const discounts = useSelector((state: RootState) => getDiscountStatuses(state, referenceNumbers));

  useEffect(() => {
    const missingReferenceNumbers = Array.from(discounts.entries())
      .filter(([key, value]) => key !== undefined && value === undefined)
      .map(([key, _]) => key);

    const referencesToFetch = missingReferenceNumbers.map((r) => {
      return {
        referenceNumber: r,
        commercialFamily: getData(references.find((ref) => ref.referenceNumber === r))?.commercialFamily ?? '',
      };
    });

    if (isTradingProfileComplete && referencesToFetch.length > 0) {
      dispatch(
        fetchReferencesDiscountsRequestSaga({
          references: referencesToFetch,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, referenceNumbers]);
};

export const useFetchReferenceStocks = (
  referenceNumber: string,
  stockRequestDetails?: ReferenceStockTradingDataRequestDetail,
): void => {
  useFetchReferencesStocks([referenceNumber], stockRequestDetails ? [stockRequestDetails] : []);
};

export const useFetchReferencesStocks = (
  referenceNumbers: string[],
  stockRequestDetails?: ReferenceStockTradingDataRequestDetail[],
): void => {
  const typesMap = useSelector((state: RootState) => getReferenceTypes(state, referenceNumbers));
  const sourceMap = useSelector((state: RootState) => getReferenceSources(state, referenceNumbers));
  const referencesToFetchStocks = referenceNumbers.map((r) => {
    return {
      referenceNumber: r,
      type: typesMap.get(r) ?? 'STANDARD',
      quantity: 1,
      origin: stockRequestDetails?.find((ref) => ref.referenceNumber === r)?.origin,
      supplierCode: stockRequestDetails?.find((ref) => ref.referenceNumber === r)?.supplierCode,
      referenceSource: sourceMap.get(r) ?? 'STANDARD',
    };
  });
  useFetchStocks(referencesToFetchStocks);
};

export const useFetchTireStocks = (referenceNumbers: string[]): void => {
  const setParam = useSelector(getTireSetParam);
  const otherSection = useSelector(getBasketOtherSection);
  const currentBasketReferencesMap = otherSection?.references?.reduce(
    (acc, next) => acc.set(next.referenceNumber, next.quantity),
    new Map<string, number>(),
  );
  const stocks = useSelector((state: RootState) => getStocksMap(state, referenceNumbers));

  const referencesToFetchStocks = referenceNumbers
    .map((r) => {
      const currQuantity = currentBasketReferencesMap.get(r);
      return {
        referenceNumber: r,
        type: 'TIRE',
        quantity: currQuantity ?? setParam ?? 1,
        origin: undefined,
        supplierCode: undefined,
        referenceSource: 'STANDARD',
      } as ReferenceStockTradingDataRequestDetail;
    })
    .filter((ref) => {
      const stock = stocks.get(ref.referenceNumber);
      return stock === undefined || (stock?.data !== undefined && stock?.data?.confirmedQuantity !== ref.quantity);
    });
  useFetchStocks(referencesToFetchStocks, true);
};

export const useFetchReuseStocks = (referenceNumbers: string[], nodeId?: string): void => {
  const dispatch = useDispatch();
  const userCountry = useSelector(getUserCountry);
  const referenceTypesMap = useSelector((state: RootState) => getReferenceTypes(state, referenceNumbers));
  const reuseStocks = useSelector((state: RootState) => getReuseStocksMap(state, referenceNumbers));

  useEffect(() => {
    if (userCountry !== Country.FR.key || referenceNumbers.length < 1 || (nodeId && !REUSE_NODE_IDS.includes(nodeId))) {
      return;
    }

    const diff = referenceNumbers.filter((ref) => {
      const curr = reuseStocks.get(ref);
      const refType = referenceTypesMap.get(ref);
      return refType !== TIRE && curr === undefined;
    });

    if (diff.length < 1) return;
    dispatch(
      fetchReuseStocksRequestSaga({
        referenceNumbers: diff,
        nodeId,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, referenceNumbers, referenceTypesMap, nodeId]);
};

export const useFetchEquivalentReferences = (
  reference: string,
  isDiscontinued: boolean,
): SearchData<string[]> | undefined => {
  const dispatch = useDispatch();
  const equivalentReferences = useSelector((state: RootState) => getEquivalentReferences(state, reference));

  useEffect(() => {
    if (equivalentReferences?.searchStatus === undefined && isDiscontinued) {
      dispatch(fetchEquivalentReferencesRequestSaga({ reference }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, reference, isDiscontinued]);
  return equivalentReferences;
};

export const useFetchStocks = (
  requestStockDetails: ReferenceStockTradingDataRequestDetail[],
  fetchEvenIfExists = false,
): void => {
  const dispatch = useDispatch();
  const tradingProfile = useSelector(getTradingProfile);
  const isTradingProfileComplete = tradingProfile?.isComplete();
  const referenceNumbers = requestStockDetails.map((r) => r.referenceNumber);
  const stocks = useSelector((state: RootState) => getStocksMap(state, referenceNumbers));

  useEffect(() => {
    const diff = requestStockDetails.filter((detail) => {
      const curr = stocks.get(detail.referenceNumber);
      return curr === undefined || (curr.data && curr.data.confirmedQuantity !== detail.quantity);
    });

    if (isTradingProfileComplete && diff.length > 0) {
      dispatch(
        fetchReferencesStocksRequestSaga({
          data: diff,
          fetchEvenIfExists,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, JSON.stringify(requestStockDetails)]);
};

export const useFetchRepairPrices = (referenceNumbers: string[], vehicleKey?: string): void => {
  const dispatch = useDispatch();
  const tradingProfile = useSelector(getTradingProfile);
  const isTradingProfileComplete = tradingProfile?.isComplete();
  const prices = useSelector((state: RootState) => getRepairPricesMap(state, referenceNumbers));
  const references = useSelector((state: RootState) => getDHReferencesMap(state, { referenceNumbers, vehicleKey }));

  useEffect(() => {
    const missingReferenceNumbers = Array.from(prices.entries())
      .filter(([_, value]) => value === undefined)
      .map(([key, _]) => key);

    const referencesToFetch = missingReferenceNumbers.map((r) => {
      return { referenceNumber: r, commercialFamily: getData(references.get(r))?.familyCode ?? '' };
    });

    if (isTradingProfileComplete && missingReferenceNumbers.length > 0) {
      dispatch(fetchReferencesRepairPriceRequestSaga({ references: referencesToFetch }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isTradingProfileComplete, referenceNumbers]);
};

export const useFetchFullTradingData = (referenceNumber: string, vehicleKey?: string): void => {
  useFetchPrices([referenceNumber], vehicleKey);
  useFetchReferencesStocks([referenceNumber]);
};

export const useFetchFullTradingDatas = (
  referenceNumbers: string[],
  vehicleKey?: string,
  stockRequestDetails?: ReferenceStockTradingDataRequestDetail[],
): void => {
  useFetchPrices(referenceNumbers, vehicleKey);
  useFetchReferencesStocks(referenceNumbers, stockRequestDetails);
};

export const useFetchPlatesForReference = (
  referenceNumber: string,
  vehicleKey: string | undefined,
): SearchData<ReferencePlateLine[]> => {
  const dispatch = useDispatch();
  const reference = useSelector((state: RootState) => getDHReference(state, { vehicleKey, referenceNumber }));
  const ref = getData(reference);

  useEffect(() => {
    if (vehicleKey && ref && ref?.referencePlateLinesStatus === undefined) {
      dispatch(
        fetchPlatesForReferenceRequestSaga({
          referenceNumber,
          vehicleKey,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referenceNumber, ref?.referencePlateLinesStatus, vehicleKey]);
  return {
    searchStatus: ref?.referencePlateLinesStatus,
    data: ref?.referencePlateLines,
  };
};
