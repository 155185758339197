import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchFamilyCodesRequestSaga,
  getFamilyCodes,
  getFamilyCodesLanguage,
  validateProductsRequestSaga,
} from 'domains/promotion/Promotion.store';
import { CFSectionLocal, CreatePromotionModel, PromotionItem } from 'domains/promotion/Promotion.types';
// eslint-disable-next-line max-len
import { getWebLanguage } from 'domains/user';
import { FamilyCodesInput } from 'pages/BackOfficePage/BackOfficeCategories/Promotion/CreatePromotion/PriceOptionStep/FamilyCodesInput';
import { Flex, Text } from 'UI';
import { hasLocalCommercialFamiliesCodeDuplicates } from './PromotionErrorNotifications';

const MAX_COMMERCIAL_FAMILY_CODES = 20;

const createNewCommercialFamilyCodes = (
  commercialFamilyCodes: CFSectionLocal[] | undefined,
  index: number,
  removedItem: PromotionItem | undefined,
  newItem: string,
  containsLocalDuplicate: boolean,
): CFSectionLocal[] | undefined => {
  return commercialFamilyCodes?.map((c, i) => {
    if (i === index) {
      if (removedItem) {
        const cfCodes = c.items.filter((item) => item.item !== removedItem.item);
        return { ...c, items: cfCodes };
      }
      return {
        ...c,
        items: [...c.items, { item: newItem, removable: true, alreadyLocallyPromoted: containsLocalDuplicate }],
      };
    }
    return c;
  });
};

export function updateLastLocallyPromotedCFCode(
  commercialFamilyCodes: CFSectionLocal[] | undefined,
  code: string | undefined,
): CFSectionLocal[] | undefined {
  if (!code) {
    return commercialFamilyCodes;
  }
  const occurrences = commercialFamilyCodes?.reduce((count, section) => {
    const filteredItem = section.items.filter((item) => item.item === code);
    count += filteredItem.length;
    return count;
  }, 0);
  if (occurrences === 1) {
    return commercialFamilyCodes?.map((section) => {
      return {
        ...section,
        items: section.items.map((item) => {
          if (item.item === code) {
            return {
              ...item,
              alreadyLocallyPromoted: false,
            };
          }
          return {
            ...item,
          };
        }),
      };
    });
  } else {
    return commercialFamilyCodes;
  }
}

export function CommercialFamilies({
  promotion,
  setPromotion,
}: {
  promotion: CreatePromotionModel;
  setPromotion: (x: CreatePromotionModel) => void;
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const commercialFamilyCodesSections = useMemo(() => promotion.commercialFamilyCodes ?? [{ items: [], discount: 0 }], [
    promotion,
  ]);

  const notEditableCommercialFamilyCodeCount = useMemo(
    () => promotion.commercialFamilyCodes?.map((section) => section.items.filter((code) => !code.removable).length),
    [promotion],
  );
  const updateCommercialFamilyCodes = (newItems: string[], index: number) => {
    if (newItems.length > MAX_COMMERCIAL_FAMILY_CODES) {
      return;
    }
    const sectionLocal = promotion.commercialFamilyCodes && promotion.commercialFamilyCodes[index];
    const currentCodes = sectionLocal?.items;
    const newItem = newItems.filter((input) => !currentCodes?.find((code) => input === code.item))[0];
    const containsLocalDuplicate = hasLocalCommercialFamiliesCodeDuplicates(
      newItem,
      promotion.commercialFamilyCodes?.filter((_item, id) => id !== index),
    );

    if (newItem && currentCodes?.find((code) => code.item.toUpperCase() === newItem.toUpperCase())) {
      return;
    }

    const removedItem = sectionLocal?.items.find((input) => !newItems.find((code) => input.item === code));

    const updatedCommercialFamilyCodes = createNewCommercialFamilyCodes(
      promotion.commercialFamilyCodes,
      index,
      removedItem,
      newItem,
      containsLocalDuplicate,
    );
    const newCommercialFamilyCodes = updateLastLocallyPromotedCFCode(updatedCommercialFamilyCodes, removedItem?.item);

    const notEditableCodeCount = notEditableCommercialFamilyCodeCount && notEditableCommercialFamilyCodeCount[index];

    const updatedPromotion: CreatePromotionModel = {
      ...promotion,
      commercialFamilyCodes: newCommercialFamilyCodes ?? [
        {
          items: newItems.map((item) => {
            return { item, removable: true };
          }),
          discount: 0,
          editing: false,
        },
      ],
    };

    if (
      newCommercialFamilyCodes &&
      notEditableCodeCount &&
      newCommercialFamilyCodes.length &&
      newCommercialFamilyCodes[0]?.items.length < notEditableCodeCount
    ) {
      newCommercialFamilyCodes?.pop();
    } else {
      setPromotion(updatedPromotion);
    }

    if (!containsLocalDuplicate) {
      dispatch(validateProductsRequestSaga({ promotion: updatedPromotion }));
    }
  };

  const data = useFetchFamilyCodes();

  return (
    <Flex direction={'column'} size={4}>
      <Text type={'h5_bold'}>{t('backoffice.promotion.select_promoted_products', 'Select promoted products')}</Text>
      {commercialFamilyCodesSections &&
        commercialFamilyCodesSections?.map((section: CFSectionLocal, index: number) => {
          const inputValue = section?.items.map((item) => item.item);
          return (
            <FamilyCodesInput
              key={`commercial_family_codes_${index}`}
              index={index}
              inputValue={inputValue}
              data={data}
              onUpdate={(newItems) => updateCommercialFamilyCodes(newItems, index)}
              promotion={promotion}
            />
          );
        })}
    </Flex>
  );
}

export const useFetchFamilyCodes = () => {
  const dispatch = useDispatch();
  const familyCodes = useSelector(getFamilyCodes);
  const familyCodesLanguage = useSelector(getFamilyCodesLanguage);
  const webLanguage = useSelector(getWebLanguage);

  useEffect(() => {
    if (familyCodesLanguage !== webLanguage.key) {
      dispatch(fetchFamilyCodesRequestSaga());
    }
  }, [dispatch, webLanguage, familyCodesLanguage]);

  return familyCodes;
};
